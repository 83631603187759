import { useState, useEffect } from 'react';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Box, Divider, Typography } from '@mui/material';

import { InviteStatus } from 'src/generated/gql/graphql';
import Loading from 'src/components/Loading';
import { paths } from 'src/routes/paths';
import { generateLinkPath } from 'src/routes/RouteUtil';
import InnerContainer from 'src/pages/Program/components/InnerContainer';
import GuestDetailsForm from 'src/pages/Guest/Invite/GuestDetailsForm';
import GuestPreviewDrawer from 'src/pages/Guest/Invite/GuestPreviewDrawer';
import GuestInviteStepper from 'src/pages/Guest/Invite/GuestInviteStepper';
import GuestPaymentForm from 'src/pages/Guest/Invite/GuestPaymentForm';
import {
  guestCoMarketOrderDetailsAndPreview,
  getGuestUserProfile
} from 'src/pages/Guest/Invite/queries';
import { profileType } from 'src/pages/Guest/Invite/Constants';

const GuestInvite = () => {
  const { orderId } = useParams<{ orderId: string }>();

  const { data: userProfileData, loading: userProfileLoading } = useQuery(
    getGuestUserProfile,
    {
      variables: {
        userProfileType: profileType
      }
    }
  );

  const profileData = userProfileData?.me?.userProfile?.profileData;

  const { data, loading } = useQuery(guestCoMarketOrderDetailsAndPreview, {
    variables: { orderId },
    skip: !orderId
  });
  const guestOrderDetails = data?.guestCoMarketOrderDetailsAndPreview;

  const hostName = guestOrderDetails?.hostName;
  const hostEmail = guestOrderDetails?.hostEmail;
  const inviteExpiresInDays = guestOrderDetails?.inviteExpiresInDays;

  const [previewDrawerOpen, setPreviewDrawerOpen] = useState(true);
  const [activeStep, setActiveStep] = useState(profileData ? 1 : 0);

  useEffect(() => {
    if (profileData) {
      setActiveStep(1);
    }
  }, [profileData]);

  if (loading || userProfileLoading) {
    return <Loading />;
  }

  // if the invite has already been accepted, redirect to the program page
  if (guestOrderDetails?.inviteStatus === InviteStatus.Accepted) {
    const redirectPath = generateLinkPath(paths.guest.program.base, {
      orderId
    });

    return <Redirect to={redirectPath} />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        pt: 4,
        position: 'relative',
        paddingRight: theme =>
          previewDrawerOpen ? `${theme.evSizes.previewDrawerWidth}px` : 0
      }}
    >
      <InnerContainer previewDrawerOpen={previewDrawerOpen}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <GuestInviteStepper activeStep={activeStep} />

          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontWeight: 'bold',
              fontSize: '30px',
              mb: 4,
              textAlign: 'center'
            }}
          >
            {activeStep === 0 &&
              !loading &&
              t('guestInvite.titleStep0', { hostName })}
            {activeStep === 1 && !loading && t('guestInvite.titleStep1')}
          </Typography>

          <Typography variant="body1" sx={{ mb: 4 }}>
            {activeStep === 0 &&
              !loading &&
              t('guestInvite.descriptionStep0', {
                hostName,
                timeLeft: `${inviteExpiresInDays} ${t('guestInvite.days')}`
              })}
            {activeStep === 1 &&
              !loading &&
              t('guestInvite.descriptionStep1', {
                hostName,
                timeLeft: `${inviteExpiresInDays} ${t('guestInvite.days')}`
              })}
          </Typography>

          {activeStep === 0 && (
            <GuestDetailsForm
              onSuccess={() => setActiveStep(1)}
              onError={() => setActiveStep(0)}
              userProfileData={profileData}
            />
          )}
          {activeStep === 1 && guestOrderDetails && !loading && (
            <GuestPaymentForm
              guestOrderDetails={guestOrderDetails}
              orderId={orderId}
            />
          )}

          {/* Footer */}
          <Box sx={{ mt: 8, px: 4 }}>
            <Divider sx={{ mb: 3 }} />
            <Typography variant="body1" sx={{ mb: 1, textAlign: 'center' }}>
              {t('guestInvite.footer')}
            </Typography>
            <Typography
              component="a"
              sx={{ textAlign: 'center', display: 'block' }}
              href={`mailto:${hostEmail}`}
            >
              {hostEmail}
            </Typography>
          </Box>
        </Box>

        {guestOrderDetails && (
          <GuestPreviewDrawer
            previewDrawerOpen={previewDrawerOpen}
            setPreviewDrawerOpen={setPreviewDrawerOpen}
            guestOrderDetails={guestOrderDetails}
          />
        )}
      </InnerContainer>
    </Box>
  );
};

export default GuestInvite;
